<template>
  <validation-observer ref="simpleRules">
  <component>

    <b-card v-if="session > 0">

      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon
          icon="PlusCircleIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Tambah Hak Akses
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Akses -->
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <b-form-group
              label="Akses"
              label-for="akses"
            >
            <validation-provider
              #default="{ errors }"
              name="Akses"
              rules="required"
          >
              <b-form-input
                v-model="userDataInfo.hakakses_name"
                type="text"
                placeholder="Input Akses"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
            <b-col
              cols="12"
              md="3"
              lg="3"
            >
              <b-form-group
                label="Status"
                label-for="status"
                label-class="mb-1"
              >
                <b-form-radio-group
                  id="menu_active"
                  v-model="userDataInfo.hakakses_status"
                  :options="aktifOptions"
                  value=""
                />
              </b-form-group>
            </b-col>
        </b-row>

        <!-- PERMISSION TABLE -->
        <b-row>
          <b-col
            cols="12"
            md="8"
            lg="8"
          >
            <b-card
              no-body
              class="border mt-1"
            >
              <b-card-header class="p-1">
                <b-card-title class="font-medium-2">
                  <feather-icon
                    icon="LockIcon"
                    size="18"
                  />
                  <span class="align-middle ml-50">Permission</span>
                </b-card-title>
              </b-card-header>

              <b-table
                striped
                responsive
                class="mb-0"
                :items="permissionsData"
              >
                <template #cell(access)="{ item }">
                  <b-form-checkbox
                    v-model="item.access"
                    inline
                  />
                  </b-form-checkbox>
                </template>

              </b-table>

            </b-card>
          </b-col>

        </b-row>

        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
             @click.prevent="validationForm"
          >
            Save Changes
          </b-button>

          <b-button
            variant="outline-secondary"
            :to="{ name: 'apps-hak-akses-list'}"
          >
            Back
          </b-button>
          <!-- {{ permissionsData }} -->
        </b-col>
      </b-form>
    </b-card>

  </component>
  </validation-observer>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'
import useAppConfig from '@core/app-config/useAppConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
    BTable,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
      async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const params = new URLSearchParams()
          params.append('param', 'post_hakAkses_tambah')
          params.append('hakakses_name', this.userDataInfo.hakakses_name)
          params.append('hakakses_status', this.userDataInfo.hakakses_status)
          params.append('created_by', localStorage.getItem('emp_id'))
          params.append('permissionsData', JSON.stringify(this.permissionsData))
          const result = await axios.post(`${useAppConfig().AppUrl}hakakses/add`, params)

          if (result.data.message === 'Berhasil menambah data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Berhasil Disimpan',
                variant: 'success',
              },
            })
            this.$router.push('/apps/hak-akses/list')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disimpan!',
                variant: 'danger',
              },
            })
            console.log(result)
          }
        }
      })
    },        
    },
  setup(props) {
    const session = ref([])
    onMounted(async () => {
      // alert(router.currentRoute.name)
      let char = (router.currentRoute.name).split('-')
      let finChar = ''
      for (let i = 0; i < (char.length)-1; i++) {
        const element = char[i];
        finChar += element+'-'
      }
      console.log(finChar.slice(0, -1));
      let result = await axios.post(`${useAppConfig().AppUrl}global/cekAksesPrevilege`, {emp_id: localStorage.getItem('emp_id'), route_name: finChar.slice(0, -1)})
      session.value= result.data[0].data[0].previlege
    })

    const userDataInfo = ref({
      role_name: '',
    })

    const aktifOptions = [
      { text: 'Aktif', value: '1' },
      { text: 'Tidak Aktif', value: '0' },
    ]

    const permissionsData = [
      {
        menu: 'Master',
        urut: 1,
        module: 'Hak Akses',
        access: false,
        route: 'apps-hak-akses-list',
        icon: 'SettingsIcon',
      },
      {
        menu: 'Master',
        urut: 1,
        module: 'Divisi',
        access: false,
        route: 'apps-divisi-list', 
        icon: 'SettingsIcon',
      },
      {
        menu: 'Master',
        urut: 1,
        module: 'Mapping Divisi',
        access: false,
        route: 'apps-mapping-divisi-list',
        icon: 'SettingsIcon',
      },
      {
        menu: 'Master',
        urut: 1,
        module: 'Mapping User',
        access: false,
        route: 'apps-mapping-user-list',
        icon: 'SettingsIcon',
      },
      {
        menu: 'Master',
        urut: 1,
        module: 'Approval',
        access: false,
        route: 'apps-approval-sales-list',
        icon: 'SettingsIcon',
      },
      {
        menu: 'Master',
        urut: 1,
        module: 'Kategori Produk',
        access: false,
        route: 'apps-kategori-produk-list',
        icon: 'SettingsIcon',
      },
      {
        menu: 'Master',
        urut: 1,
        module: 'Email Mengetahui',
        access: false,
        route: 'apps-email-mengetahui-list',
        icon: 'SettingsIcon',
      },
      {
        menu: 'Sales',
        urut: 2,
        module: 'List Pengajuan',
        access: false,
        route: 'apps-pengajuan-sales-list',
        icon: 'UsersIcon',
      },
      {
        menu: 'Approval Sales',
        urut: 3,
        module: 'List Pengajuan',
        access: false,
        route: 'apps-approval-list',
        icon: 'UserCheckIcon',
      },
      {
        menu: 'Monitoring',
        urut: 4,
        module: 'List Pengajuan',
        access: false,
        route: 'apps-monitoring-pengajuan-list',
        icon: 'SearchIcon',
      },
    ]

    return {
      session,
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      userDataInfo,
      codeVariant,
      permissionsData,
      aktifOptions,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
